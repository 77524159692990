import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import PropertySummary from './components/PropertySummary';
import { addressWithoutProtocol, mainDomainWithTld } from 'helpers/url';

const WizardSummary = ({ propertyConfiguration }) => {
  const propertyDomain = mainDomainWithTld(addressWithoutProtocol(propertyConfiguration.address));

  return (
    <div>
      <h1>Log Configuration<br /></h1>

      <PropertySummary propertyAddress={propertyConfiguration.address} />
      <form>
        <div className='checkbox'>
          <label>
            <Field
              name='property.separate_https'
              component='input'
              type='checkbox'
              checked={propertyConfiguration.separate_https}
            />
            <b>Separate http and https into distinct Excel files</b><br />
            By default, Keylime Toolbox aggregates http and https requests into a
            single set of Excel files. However, you may find it useful to look at
            this data separately (for instance, when monitoring an http to https migration.)
            Check this box to separate http and https requests into distinct Excel files.
          </label>
        </div>
        <div className='checkbox'>
          <label>
            <Field
              name='property.multiple_domains'
              component='input'
              type='checkbox'
              checked={propertyConfiguration.multiple_domains}
            />
            <b>Include requests for other domains and provide them in a separate file</b><br />
            Some server log access files contain requests for multiple domains.
            By default, Keylime Toolbox extracts only requests that match <code>{propertyConfiguration.address}</code>.
            Check this box to extract all other domain requests and provide them in a
            separate file.
          </label>
        </div>
        <div className='checkbox'>
          <label>
            <Field
              name='property.separate_subdomains'
              component='input'
              type='checkbox'
              checked={propertyConfiguration.separate_subdomains}
            />
            <b>Separate requests for subdomains into a separate file</b><br />
            Many domains include subdomains (such as <code>{"example." + propertyDomain}</code>).
            By default, Keylime Toolbox includes all subdomain requests in the
            same Excel file as www and non-www requests
            (<code>{propertyDomain}</code> and <code>{"www." + propertyDomain}</code>).
            Check this box to separate subdomain requests into a separate file.
          </label>
        </div>
        <p>For a different logs configuration, finish the wizard and then
          contact us at <a href="mailto:logs@keylimetoolbox.com">logs@keylimetoolbox.com</a>.
        </p>
      </form>
    </div>
  );
};

WizardSummary.propTypes = {
  propertyConfiguration: PropTypes.shape({
    address: PropTypes.string.isRequired,
    separate_https: PropTypes.bool.isRequired,
    multiple_domains: PropTypes.bool.isRequired,
    separate_subdomains: PropTypes.bool.isRequired
  }).isRequired
};

export default WizardSummary;
