import React from 'react'
import PropTypes from 'prop-types'
import { APP_NAME, APP_URL, SUPPORT } from '../../config';

const Footer = ({ year = new Date().getFullYear() }) => (
  <footer id="footer">
    <div className="container navbar">
      <p className="navbar-text">
        <a className="navbar-link" href={`mailto:${SUPPORT}`} tabIndex="-1">{SUPPORT}</a>
        <br />
        &copy; {year} {APP_NAME} |
        <a href={`${APP_URL}/terms_of_service`} tabIndex="-1">Terms of Service</a>
      </p>
    </div>
  </footer>
);

Footer.propTypes = {
  year: PropTypes.number
};

export default Footer;
