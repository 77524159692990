import { connect } from 'react-redux';
import { reduxForm, isValid, isSubmitting, submit, hasSubmitSucceeded } from 'redux-form';
import PropertyWizard from 'components/PropertyWizard';
import {
  validateRequiredFields,
  validateUrls,
  validateUrlsOwnership
} from 'helpers/validators';
import { APP_URL } from '../../config';
import withRegistryAccess from 'hoc/withRegistryAccess';
import { onSubmit } from './onSubmit'

export const formName = 'propertyWizard';

const form = reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: values => ({
    ...validateRequiredFields([
      'property.separate_aggregation',
      'property.multiple_domain',
      'property.separate_subdomains'
    ], values),
    ...validateUrls(['property.address'], values)
  }),
  asyncBlurFields: ['property.address'],
  asyncValidate: validateUrlsOwnership,
  initialValues: {
    property: {
      separate_https: false,
      multiple_domains: false,
      separate_subdomains: false
    }
  },
  onSubmit
})(PropertyWizard);

const mapStateToProps = (state, ownProps) => {
  const { appRegistry } = ownProps;
  const internalRouter = appRegistry.internalRouter;
  const { nextStep, prevStep } = internalRouter;

  return {
    isFormValid: isValid(formName)(state),
    cancelWizard: () => window.location.href = `${APP_URL}/summary`,
    nextStep: () => nextStep ? internalRouter.goTo(nextStep) : null,
    prevStep: () => prevStep ? internalRouter.goTo(prevStep) : null,
    submissionBlocked: isSubmitting(formName)(state) || hasSubmitSucceeded(formName)(state),
    isLastStep: () => !nextStep
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitAction: () => dispatch(submit(formName))
  }
};

export default withRegistryAccess(connect(mapStateToProps, mapDispatchToProps)(form));
