import React from 'react';
import PropTypes from 'prop-types';
import { addressWithoutProtocol, isMainDomain } from 'helpers/url';
import LongHeading from 'components/LongHeading';

const Aggregation = ({ propertyAddress }) => {
  const propertyAddressWithoutProtocol = addressWithoutProtocol(propertyAddress);

  const addresses = isMainDomain(propertyAddress) ? [
      `http://www.${propertyAddressWithoutProtocol}`,
      `http://${propertyAddressWithoutProtocol}`,
      `https://www.${propertyAddressWithoutProtocol}`,
      `https://${propertyAddressWithoutProtocol}`
    ] : [
      `http://${propertyAddressWithoutProtocol}`,
      `https://${propertyAddressWithoutProtocol}`
    ]
  addresses.filter(address => !!address);

  return (
    <form>
      <LongHeading>Domain Configuration:<code>{propertyAddressWithoutProtocol}</code></LongHeading>
      <p>You added the domain as <code>{propertyAddress}</code>.</p>
      <p>By default, Keylime Toolbox will include any search engine requests to the following URL variations:</p>

      <ul>
        {addresses.map(address => <li key={address}>{address}</li>)}
      </ul>
    </form>
  );
};

Aggregation.propTypes = {
  propertyAddress: PropTypes.string.isRequired
};

export default Aggregation;
