import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import AddPropertyRoute from 'components/AddPropertyRoute';
import logoSvg from 'assets/images/keylime_logo.svg';
import { APP_NAME, APP_URL } from '../../config';
import PropertyWizard from 'containers/PropertyWizard';
import WizardSummary from 'containers/WizardSummary';
import withRegistryAccess from 'hoc/withRegistryAccess';

const Main = ({ appRegistry, session, session: { clientName } }) => (
  <div className="main-container">
    <NavBar user={{ name: clientName }} />
    <div className="container full main-content">
      <div className="row row-offcanvas row-offcanvas-left">
        <div className="col-sm-2 sidebar-offcanvas" id="sidebar" role="navigation">
          <div className="clearfix">
            <a className="navbar-brand hidden-xs" href={`${APP_URL}`}>
              <img alt={APP_NAME} src={logoSvg} />
            </a>
          </div>
        </div>
        <div className="col-sm-10">
          <div className="row" id="main">
            <div className="col-xs-12">
              <PropertyWizard session={session}>
                <Switch>
                  <Route exact path="/" render={() => <Redirect to="/add-property" />} />
                  <Route path="/add-property" component={AddPropertyRoute} />
                  <Route path="/summary" component={WizardSummary} />
                </Switch>
              </PropertyWizard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

Main.propTypes = {
  appRegistry: PropTypes.object.isRequired,
  session: PropTypes.shape({
    clientName: PropTypes.string,
    returnUrl: PropTypes.string
  })
};

export default withRegistryAccess(Main);
