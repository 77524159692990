import parseDomain from 'parse-domain';
import URL from 'url-parse';
import { APP_URL, DOMAIN_TLD } from '../config';

export const addressWithoutProtocol = address => {
  const parsedUrl = new URL(address);
  const hostname = parsedUrl.protocol === 'sc-domain:' ? parsedUrl.pathname : parsedUrl.hostname

  return hostname.indexOf('www.') === 0 ? hostname.replace('www.', '') : hostname
};

export const isMainDomain = address => {
  if (/^sc-domain:/.test(address)) { return true; }

  const parsedDomain = parseDomain(addressWithoutProtocol(address));

  return parsedDomain.subdomain === ''
};

export const mainDomainWithTld = address => {
  const parsedUrl = new URL(address);
  if (parsedUrl.protocol === 'sc-domain:') {
    return parsedUrl.pathname;
  }
  
  const parsedDomain = parseDomain(address);
  if(parsedDomain == null) {
    console.log(address, parsedUrl, parsedUrl.protocol, parsedUrl.protocol === 'sc-domain:');
  }

  return `${parsedDomain.domain}.${parsedDomain.tld}`;
};

export const returnUrl = (url) => {
  if (url && url !== document.location.href) {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;

    if (hostname) {
      const domainTldReg = new RegExp('\\.' + DOMAIN_TLD + '$');

      if (domainTldReg.test(hostname) || hostname === 'localhost') {
        return `${parsedUrl.protocol}//${parsedUrl.host}`;
      }
    }
  }

  return APP_URL;
};

export const parseQueryParams = query => {
  if (!query) { return {}; }
  
  const params = /^[?#]/.test(query) ? query.slice(1) : query;
  return params.split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value) : '';
        return params;
      }, {}
    );
};
