import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'ducks/index';

class ReduxStoreConfigurator {
  middlewares = [thunkMiddleware];

  constructor () {
    if (process.env.NODE_ENV === `development`) {
      this.middlewares.push(createLogger());
    }
  }

  configureStore (initialState) {
    this.store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(...this.middlewares)
    );

    return this.store;
  }
}

export { ReduxStoreConfigurator }
export default new ReduxStoreConfigurator();
