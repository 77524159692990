export default class KeylimeClient {
  constructor (httpClient, authenticityToken) {
    this.httpClient = httpClient;
    this.authenticityToken = authenticityToken;
  }

  set authenticityToken (token) {
    this._authenticityToken = token;
  }

  get (url, config = {}) {
    return this.httpClient.get(
      url,
      config
    );
  }

  post (url, data, config = {}) {
    return this.httpClient.post(url, { ...data, authenticity_token: this._authenticityToken }, config);
  }

  delete (url, config = {}) {
    return this.post(
      url,
      { _method: 'DELETE' },
      { ...config, headers: { ...config.headers, 'X-HTTP-Method-Override': 'DELETE' } }
    );
  }
}
