import React from 'react';
import { Field } from 'redux-form';
import { Input } from 'components/FormElements';
import LongHeading from 'components/LongHeading';

const Name = () => (
  <form>
    <LongHeading>Add a Domain to Your Keylime Toolbox Account</LongHeading>
    <Field
      name='property.address'
      component={Input}
      placeholder='https://www.example.com'
    />

  </form>
);

export default Name;
