import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { isUrl } from 'helpers/validators';

const Button = ({ onClick, noPadding, primary, remove, to, asLink, children, type = 'button', disabled }) => {
  const buttonClass = classNames({
    'btn': true,
    'btn-primary': primary,
    'btn-link': !!to || !!asLink,
    'btn-no-padding': !!noPadding,
    'btn-remove': !!remove
  });

  const linkComponent = () => {
    if (isUrl(to)) {
      return <a href={to} className={buttonClass}>{children}</a>
    }

    return <Link to={to} className={buttonClass}>{children}</Link>
  };

  return (
    to
      ? linkComponent()
      : <button type={type} onClick={onClick} className={buttonClass} disabled={disabled}>{children}</button>
  )
};

Button.propTypes = {
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  asLink: PropTypes.bool,
  noPadding: PropTypes.bool,
  remove: PropTypes.bool
};

export default Button
