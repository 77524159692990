import React from 'react'
import PropTypes from 'prop-types'

const LongHeading = ({ children }) => <h1 className='long-heading'>{children}</h1>;

LongHeading.propTypes = {
  children: PropTypes.node.isRequired
};

export default LongHeading
