import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import whiteLogoSvg from 'assets/images/keylime_logo_white.svg';
import { APP_NAME, APP_URL } from '../../config';

export default class Navbar extends Component {
  dropdown = null

  static propTypes = {
    user: PropTypes.object.isRequired
  };

  constructor () {
    super(...arguments);

    this.state = {
      mobileMenuOpened: true,
      userMenuOpened: false
    };
  }

  componentDidMount () {
    document.addEventListener('click', this.handleWindowClick);
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleWindowClick);
  }

  handleWindowClick = event => {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.setState({ userMenuOpened: false });
    }
  }

  toggleMobileNavigation = () => {
    this.setState({ mobileMenuOpened: !this.state.mobileMenuOpened });
  }

  toggleUserDropdown = () => {
    this.setState({ userMenuOpened: !this.state.userMenuOpened });
  }

  render () {
    const { name } = this.props.user;
    const { mobileMenuOpened, userMenuOpened } = this.state;

    return (
      <div className="container full" id="header">
        <div className="navbar navbar-inverse row" id="banner">
          <div className="col-xs-12">
            <div className="navbar-header">
              <button className="navbar-toggle" type="button" onClick={this.toggleMobileNavigation}>
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand visible-xs" href={APP_URL}>
                <img alt={APP_NAME} src={whiteLogoSvg} width="104" height="50" />
              </a>
            </div>
            <div className={mobileMenuOpened ? "navbar-collapse collapse row" : "navbar-collapse row"}>
              <div className="col-sm-10 col-sm-offset-2">
                <ul className="nav navbar-nav navbar-right" role="navigation">
                  <li>
                    <a title="Account Summary" href={`${APP_URL}/summary`}>
                      <i className="fa fa-home fa-lg" />
                    </a>
                  </li>
                  <li
                    id="dropDown"
                    ref={dropdown => (this.dropdown = dropdown)}
                    className={userMenuOpened ? "dropdown open" : "dropdown"}
                  >
                    <a
                      className="dropdown-toggle"
                      onClick={this.toggleUserDropdown}
                    >
                      {name}<b className="caret" />
                    </a>
                    <ul className="dropdown-menu" role="menu">
                      <li>
                        <Link role="menuitem" to="/signout" tabIndex="-1">Sign Out</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a role="menuitem" tabIndex="-1" href={`${APP_URL}/settings/account`}>Settings</a>
                  </li>
                  <li>
                    <a
                      title="Frequently Asked Questions"
                      tabIndex="-1"
                      target="_blank"
                      href={`${APP_URL}/faqs`}
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-question fa-lg" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
