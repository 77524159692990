import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

const WizardActions = ({ nextEnabled = false, nextAction, prevAction, cancelAction, disableActions, submitAction, isLastStep }) => {
  return (
    <fieldset className="actions">
      <div className="form-group">
        {!isLastStep() && nextAction && <Button onClick={nextAction} primary disabled={!nextEnabled || disableActions}>Next</Button>}
        {isLastStep() && submitAction && (
          <Button onClick={submitAction} primary disabled={disableActions}>Submit</Button>
        )}
        <Button asLink onClick={prevAction}>Back</Button>
        <Button asLink onClick={cancelAction}>Cancel</Button>
      </div>
    </fieldset>
  )
};

WizardActions.propTypes = {
  nextEnabled: PropTypes.bool,
  nextAction: PropTypes.func,
  prevAction: PropTypes.func,
  cancelAction: PropTypes.func.isRequired,
  disableActions: PropTypes.bool,
  submitAction: PropTypes.func.isRequired,
  isLastStep: PropTypes.func.isRequired
};

export default WizardActions
