class AppRegistry {
  constructor () {
    return this
  }

  add (entryName, entryClass) {
    this[entryName] = entryClass;
  }
}

export { AppRegistry }
export default new AppRegistry();
