import React from 'react';
import PropTypes from 'prop-types';


const PropertySummary = ({ propertyAddress }) => {

  return (
    <div>
      <p>
        For complete details, see the <a href='https://www.keylimetoolbox.com/crawl-analytics-set-guide/'>Crawl Analytics Set Up Guide</a><br />
        Email us anytime with questions at <a href='mailto:logs@keylimetoolbox.com'>logs@keylimetoolbox.com</a>
      </p>

      <code>{propertyAddress}</code>
      <br />
      <br />
      <p>Select any configuration changes from the default set up:</p>
    </div>
  )
};

PropertySummary.propTypes = {
  propertyAddress: PropTypes.string.isRequired
}

export default PropertySummary;
