export async function onSubmit (values, dispatch, props) {
  const formattedData = {
    log_configuration: {
      properties: [
        values.property,
        ...(values.other_property || [])
      ]
    }
  };

  try {
    const submissionResult = await props.appRegistry.keylimeClient.post(
      `/log_configurations`, formattedData
    );

    const forwardUrl = `${props.session.returnUrl}/log_configurations/${submissionResult.data.id}/edit`;

    window.location = forwardUrl;

    return forwardUrl;
  } catch (error) {
    throw error;
  }
}
