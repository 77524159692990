import remove from 'lodash/remove'

export default class RoutingConfigurator {
  _routingConfiguration = [];

  constructor (routingConfiguration) {
    this._routingConfiguration = routingConfiguration;
  }

  get routingConfiguration () {
    return this._routingConfiguration;
  }

  addStep (step) {
    this._routingConfiguration.push(step);
  }

  addStepAtPosition (step, position) {
    this.addStepsAtPosition([step], position)
  }

  addStepsAtPosition (steps, position) {
    this._routingConfiguration.splice(position, 0, ...steps);
  }

  removeStepsAtPosition (position, stepsCount) {
    this._routingConfiguration.splice(position, stepsCount)
  }

  removeStepAtPosition (position) {
    this.removeStepsAtPosition(position, 1)
  }

  removeStep (step) {
    remove(this._routingConfiguration, existingStep => existingStep.path === step.path)
  }

  removeStepsLike (path) {
    remove(this._routingConfiguration, existingStep => existingStep.path.indexOf(path) > -1)
  }
}
