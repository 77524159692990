import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import WizardSummary from 'components/WizardSummary';
import { formName } from 'containers/PropertyWizard';

const mapStateToProps = state => {
  const valuesSelector = formValueSelector(formName)
  const propertyConfiguration = valuesSelector(state, 'property');

  return {
    propertyConfiguration
  }
};

export default connect(mapStateToProps)(WizardSummary);
