import React from 'react';
import PropTypes from 'prop-types';
import WizardActions from './components/WizardActions';

const PropertyWizard = ({ children, isFormValid, cancelWizard, nextStep, prevStep, submissionBlocked, submitAction, isLastStep }) => {
  return (
    <React.Fragment>
      {children}

      <WizardActions
        disableActions={submissionBlocked}
        cancelAction={cancelWizard}
        nextAction={nextStep}
        prevAction={prevStep}
        nextEnabled={isFormValid}
        submitAction={submitAction}
        isLastStep={isLastStep}
      />
    </React.Fragment>
  )
};

PropertyWizard.propTypes = {
  children: PropTypes.node,
  isFormValid: PropTypes.bool,
  cancelWizard: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  submitAction: PropTypes.func.isRequired,
  isLastStep: PropTypes.func.isRequired,
  submissionBlocked: PropTypes.bool
};

export default PropertyWizard;
