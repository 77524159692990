import get from 'lodash/get';
import set from 'lodash/set';
import parseDomain from 'parse-domain';
import appRegistry from 'services/AppRegistry';

export const isUrl = url => {
  try {
    const parsedUrl = new URL(url);
    if (parsedUrl.protocol === 'sc-domain') {
      const scDomainReg = new RegExp('^sc-domain:[a-z0-9-]+$');
      return scDomainReg.test(url)
    }

    return !!parseDomain(url);
  } catch (_) {
    return false;
  }
};

export const validateRequiredFields = (fields, values) => {
  const errors = {};

  fields.forEach(field => {
    const value = get(values, field, null)

    if (!value && value !== false) {
      set(errors, field, 'This field is required');
    }
  });

  return errors;
};

export const validateUrls = (fields, values) => {
  const errors = {};

  fields.forEach(field => {
    if (!isUrl(get(values, field))) {
      set(errors, field, 'Invalid URL. The URL should look like: https://www.example.com, http://www.example.com, sc-domain:example.com');
    }
  });

  return errors;
};

const traverseProperties = (arrayField, properties, values, callback) => {
  if (Array.isArray(values[arrayField])) {
    values[arrayField].forEach((_, index) => {
      properties.forEach(property => {
        const valueField = `${arrayField}.${index}.${property}`;

        callback(valueField, property, index);
      });
    });
  }
};

export const validateRequiredArrayFields = (arrayField, properties, values) => {
  let errors = {};

  traverseProperties(arrayField, properties, values, (valueField) => {
    if (!get(values, valueField, null)) {
      set(errors, valueField, 'This field is required');
    }
  });

  return errors;
};

export const validateUrlsArrayFields = (arrayField, properties, values) => {
  let errors = {};

  traverseProperties(arrayField, properties, values, (valueField) => {
    if (!isUrl(get(values, valueField))) {
      set(errors, valueField, 'Invalid URL. The URL should look like: https://www.example.com, http://www.example.com, sc-domain:example.com');
    }
  });

  return errors;
};

export const validateUrlsOwnership = (values, dispatch, props, field) => {
  const fieldValue = get(values, field);
  const error = {};

  if (!isUrl(fieldValue)) {
    return Promise.resolve();
  }

  return appRegistry.keylimeClient.get(`/domains/validate?url=${encodeURIComponent(fieldValue)}`).then(response => {

    if (response.data.errors) {
      throw set(error, field, response.data.errors[0].errors.url[0]);
    }
  });
};
