import store from 'store2';
import { isEmpty } from 'lodash';
import { returnUrl, parseQueryParams } from 'helpers/url';

export const INIT_USER = 'session/init';
export const RESET_USER = 'session/reset';
export const SET_USER = 'session/set';

const initialState = {
  clientName: null,
  authenticityToken: null,
  returnUrl: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT_USER:
      const { clientName, authenticityToken, returnUrl } = store.session('user');

      return { ...state, clientName, authenticityToken, returnUrl };
    case RESET_USER:
      return initialState;
    case SET_USER:
      return { ...action.payload };
    default:
      return state;
  }
}

export function initUser () {
  return { type: INIT_USER }
}

export function resetUser () {
  return { type: RESET_USER }
}

export function setUser (clientName, authenticityToken, returnUrl) {
  store.session('user', { clientName, authenticityToken, returnUrl });

  return { type: SET_USER, payload: { clientName, authenticityToken, returnUrl } }
}

export function authenticateUser (query, referrer) {
  return async (dispatch, getState) => {
    const urlToReturn = returnUrl(referrer);
    const queryParams = parseQueryParams(query);
    const user = store.session('user');

    if (!isEmpty(queryParams) && queryParams.n && queryParams.a) {
      await dispatch(setUser(queryParams.n, queryParams.a, urlToReturn));
    } else if (user) {
      await dispatch(initUser());
    }

    const authenticityToken = getState().session.authenticityToken;

    if (authenticityToken) {
      return { authenticated: true, returnUrl: urlToReturn, authenticityToken };
    }

    return { returnUrl: urlToReturn, authenticated: false };
  }
}
