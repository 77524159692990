import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Name } from 'components/PropertyDetails';
import Aggregation from 'containers/PropertyAggregation';

const AddPropertyRoute = () => (
  <Switch>
    <Route exact path="/add-property" render={() => <Redirect to="/add-property/name" />} />
    <Route path="/add-property/name" component={Name} />
    <Route path="/add-property/configure-aggregation" component={Aggregation} />
  </Switch>
);

export default AddPropertyRoute
