import React, { Component } from 'react'
import appRegistry from 'services/AppRegistry';

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withRegistryAccess (ComposedComponent) {
  return class extends Component {
    static displayName = `withRegistryAccess(${getDisplayName(ComposedComponent)})`;

    render () {
      return <ComposedComponent appRegistry={appRegistry} {...this.props} />
    }
  }
}
