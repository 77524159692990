import axios from 'axios'

export default class HttpClient {
  constructor (baseUrl) {
    this.http = axios.create({
      baseURL: baseUrl,
      withCredentials: true,
      validateStatus: status => {
        return (status >= 200 && status < 300) || status === 400;
      },
      headers: {
        common: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    });
  }

  get (url, config) {
    return this.http.get(url, config);
  }

  post (url, data, config) {
    return this.http.post(url, data, config);
  }

  put (url, data, config) {
    return this.http.put(url, data, config);
  }

  delete (url, config) {
    return this.http.delete(url, config);
  }
}
