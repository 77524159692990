import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/main.css';
import { createBrowserHistory } from 'history';
import storeConfigurator from 'services/ReduxStoreConfigurator';
import App from './components/App';

const history = createBrowserHistory();
const store = storeConfigurator.configureStore();

ReactDOM.render(<App store={store} history={history} />, document.getElementById('root'));
