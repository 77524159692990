import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const Input = (props) => {
  const { input, label, placeholder, className } = props;
  const { meta: { touched, error, submitting, asyncValidating } } = props;
  const fieldClass = classNames({
    'form-group': true,
    'has-error': !submitting && touched && error,
    [className]: !!className
  });

  const inputClass = classNames({
    'form-control': true,
    'async-validating': asyncValidating
  });

  return (
    <div className={fieldClass}>
      {label && <p>{label}</p>}
      <input
        {...input}
        className={inputClass}
        placeholder={placeholder || label}
      />
      {!submitting && touched && error && <span className='help-block'>{error}</span>}
    </div>
  )
};

Input.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

Input.defaultProps = {
  meta: {}
};

export default Input;
