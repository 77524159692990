import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Main from 'components/Main';
import appRegistry from 'services/AppRegistry';
import WizardRouter from 'services/WizardRouter';
import { authenticateUser } from 'ducks/session';
import HttpClient from 'services/HttpClient';
import KeylimeClient from 'services/KeylimeClient';

class App extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  };

  state = {
    ready: false
  };

  async componentDidMount () {
    const { history } = this.props;
    const initialRoutingConfiguration = [
      { path: '/add-property/name' },
      { path: '/add-property/configure-aggregation' },
      { path: '/summary' }
    ];

    const authResponse = await this.props.store.dispatch(authenticateUser(history.location.search, document.referrer));

    if (!authResponse.authenticated) {
      return window.location = `${authResponse.returnUrl}/welcome`;
    }

    this.internalRouter = new WizardRouter(history, initialRoutingConfiguration);
    appRegistry.add('internalRouter', this.internalRouter);

    const httpClient = new HttpClient(authResponse.returnUrl);
    const keylimeClient = new KeylimeClient(httpClient, authResponse.authenticityToken);
    appRegistry.add('keylimeClient', keylimeClient);

    // after refresh always return to first step
    history.push(initialRoutingConfiguration[0].path);

    this.setState({ ready: true });
  }

  render () {
    if (this.state.ready) {
      const session = this.props.store.getState().session;

      return (
        <Provider store={this.props.store}>
          <Router history={this.props.history}>
            <Main session={session} />
          </Router>
        </Provider>
      );
    }

    return null;
  }
}

export default App;
