import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Aggregation } from 'components/PropertyDetails';
import { formName } from 'containers/PropertyWizard';

const mapStateToProps = state => {
  const propertyAddress = formValueSelector(formName)(state, 'property.address');

  return {
    propertyAddress
  }
};

export default connect(mapStateToProps)(Aggregation);
