import findIndex from 'lodash/findIndex';
import RoutingConfigurator from './RoutingConfigurator';

const OTHER_PROPERTIES_PREFIX = '/other-properties';
const OTHER_PROPERTIES_PATH = `${OTHER_PROPERTIES_PREFIX}/list`;
const OTHER_PROPERTY_AGGREGATION_CONFIGURATION = `${OTHER_PROPERTIES_PREFIX}/__key__/configure-aggregation`;
const OTHER_PROPERTY_SUBDOMAINS_CONFIGURATION = `${OTHER_PROPERTIES_PREFIX}/__key__/configure-addresses`;

export default class WizardRouter {
  constructor (history, initialRoutingConfiguration, additionalPropertiesCount = 0, routingConfigurator) {
    this._history = history;
    this._routingConfigurator = routingConfigurator || new RoutingConfigurator(initialRoutingConfiguration);
    this._additionalPropertiesCount = additionalPropertiesCount
  }

  get routingConfiguration () {
    return this._routingConfigurator.routingConfiguration;
  }

  get currentStepIndex () {
    return findIndex(this.routingConfiguration, { path: this._history.location.pathname });
  }

  get currentStep () {
    return this.getStep(this.currentStepIndex);
  }

  get nextStep () {
    return this.getStep(this.currentStepIndex + 1);
  }

  get prevStep () {
    return this.getStep(this.currentStepIndex - 1);
  }

  getStep (stepNumber) {
    return this.routingConfiguration[stepNumber];
  }

  goTo (step) {
    this._history.push(step.path);
  }

  addOtherPropertiesStep () {
    this._routingConfigurator.addStepAtPosition({ path: OTHER_PROPERTIES_PATH }, -1);
  }

  removeOtherPropertiesStep () {
    this._routingConfigurator.removeStepsLike(OTHER_PROPERTIES_PREFIX);
  }

  addOtherPropertyConfigurationStep () {
    this._routingConfigurator.addStepsAtPosition([
      { path: OTHER_PROPERTY_AGGREGATION_CONFIGURATION.replace('__key__', this._additionalPropertiesCount) },
      { path: OTHER_PROPERTY_SUBDOMAINS_CONFIGURATION.replace('__key__', this._additionalPropertiesCount) }
    ], -1);

    this._additionalPropertiesCount++;
  }

  removeOtherPropertyConfigurationStep () {
    this._routingConfigurator.removeStepsAtPosition(-3, 2);

    this._additionalPropertiesCount--;
  }
}
